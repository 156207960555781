import { ReactNode, useMemo, useState } from 'react';
import classNames from 'classnames';
import { List, Map } from 'immutable';
import {
  Checkmark24,
  CategoryNew24,
  TrashCan24,
  Cut24,
  Copy24,
  Paste24,
  MisuseOutline24,
  TagGroup24,
  Group24,
  Tree24,
  Help24,
  Debug24,
  ThumbsUp24,
  ThumbsDown24,
  Chemistry24,
  CaretDown16,
  ListChecked24,
  Attachment24,
  TreeView24,
  View24,
  ViewOff24,
  CategoryAdd24,
  DataView24,
  Category24,
  Cognitive24,
} from '@carbon/icons-react';
import ReactTooltip from 'react-tooltip';
import { _ } from 'utils';
import { useLocation } from 'react-router';
import { assignTag, removeTag } from 'Sync';
// import aiSrc from './svgs/ai.svg';
// import aiSrc2 from './svgs/ai2.svg';

import { Select } from './components';
import type { BaseOption } from './components';

const AiRootIcon = props => {
  return (
    <svg width={20} height={20} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.0526485 2.496C0.31594 1.0747 1.53541 0 3 0H13.5H16.125H21C22.6569 0 24 1.37538 24 3.07199V20.928C24 22.6246 22.6569 24 21 24H3.00002C1.34317 24 1.71661e-05 22.6246 1.71661e-05 20.928V3.07199H0C0 3.06849 5.72507e-06 3.06499 1.71661e-05 3.06149V2.496H0.0526485ZM2.25002 3.06667L2.25002 20.928C2.25002 21.3521 2.58581 21.696 3.00002 21.696H21C21.4143 21.696 21.75 21.3521 21.75 20.928V3.07199C21.75 2.64784 21.4143 2.304 21 2.304H16.125H13.5H3C2.58752 2.304 2.25282 2.64496 2.25002 3.06667ZM12.4279 18.2398L11.614 15.3175H7.82096L7.007 18.2398H4.50002L8.24421 5.95185H11.3047L15 18.2398H12.4279ZM9.74189 8.38128H9.66049L8.407 13.0465H11.0117L9.74189 8.38128ZM19.05 5.95185H16.5V18.2398H19.05V5.95185Z"
        fill="inherit"
      />
    </svg>
  );
};

const AiIcon = props => {
  return (
    <svg
      viewBox="0 0 24 24"
      style={{ minWidth: 26, minHeight: 26 }}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.62577 3.69889C5.81555 3.12185 6.63182 3.12185 6.8216 3.69889L7.76 6.55221C7.82245 6.74209 7.97139 6.89103 8.16128 6.95348L11.0146 7.89188C11.5916 8.08166 11.5916 8.89794 11.0146 9.08771L8.16128 10.0261C7.97139 10.0886 7.82245 10.2375 7.76 10.4274L6.8216 13.2807C6.63182 13.8577 5.81555 13.8577 5.62577 13.2807L4.68737 10.4274C4.62492 10.2375 4.47598 10.0886 4.28609 10.0261L1.43278 9.08771C0.855741 8.89794 0.85574 8.08166 1.43278 7.89188L4.28609 6.95348C4.47598 6.89103 4.62492 6.74209 4.68737 6.55221L5.62577 3.69889Z"
        fill="inherit"
      />
      <path
        d="M12.5699 1.18489C12.6753 0.864314 13.1288 0.864314 13.2342 1.18489L13.7556 2.77006C13.7903 2.87556 13.873 2.9583 13.9785 2.99299L15.5637 3.51433C15.8842 3.61976 15.8842 4.07325 15.5637 4.17868L13.9785 4.70001C13.873 4.73471 13.7903 4.81745 13.7556 4.92294L13.2342 6.50812C13.1288 6.82869 12.6753 6.8287 12.5699 6.50812L12.0485 4.92294C12.0138 4.81745 11.9311 4.73471 11.8256 4.70001L10.2404 4.17868C9.91986 4.07325 9.91986 3.61976 10.2404 3.51433L11.8256 2.99299C11.9311 2.9583 12.0138 2.87556 12.0485 2.77007L12.5699 1.18489Z"
        fill="inherit"
      />
      <path
        d="M6 16V21C6 22.1046 6.89543 23 8 23H20C21.1046 23 22 22.1046 22 21V9C22 7.89543 21.1046 7 20 7H15V8.5H20C20.2761 8.5 20.5 8.72386 20.5 9V21C20.5 21.2761 20.2761 21.5 20 21.5H8C7.72386 21.5 7.5 21.2761 7.5 21V16H6Z"
        fill="inherit"
      />
      <path
        d="M14.2853 19L13.7426 17.0974H11.214L10.6713 19H9L11.4961 11H13.5364L16 19H14.2853ZM12.4946 12.5817H12.4403L11.6047 15.6189H13.3411L12.4946 12.5817Z"
        fill="inherit"
      />
      <path d="M17 11H18.7V19H17V11Z" fill="inherit" />
    </svg>
  );
};

const AiTurboIcon = props => {
  return (
    <svg viewBox="0 0 24 24" style={{ height: 24 }} fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
      <mask
        id="mask0_840_71"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="1"
        y="2"
        width="22"
        height="22"
      >
        <path d="M8 9.6129H11.3871V13H12.6129V9.6129H16V8.3871H12.6129V5H11.3871V8.3871H8V9.6129Z" fill="inherit" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.75 19H21C21.3978 19 21.7794 19.158 22.0607 19.4393C22.342 19.7206 22.5 20.1022 22.5 20.5V23.5H21V20.5H12.75V23.5H11.25V20.5H3V23.5H1.5V20.5C1.5 20.1022 1.65804 19.7206 1.93934 19.4393C2.22064 19.158 2.60218 19 3 19H11.25V16H6.75C6.35232 15.9995 5.97105 15.8414 5.68984 15.5602C5.40864 15.279 5.25046 14.8977 5.25 14.5V4C5.2504 3.6023 5.40856 3.221 5.68978 2.93978C5.971 2.65856 6.3523 2.5004 6.75 2.5H17.25C17.6477 2.50046 18.0289 2.65864 18.3102 2.93984C18.5914 3.22105 18.7495 3.60232 18.75 4V14.5C18.7495 14.8977 18.5913 15.2789 18.3101 15.5601C18.0289 15.8413 17.6477 15.9995 17.25 16H12.75V19ZM17.25 4H6.75V14.5H17.2515L17.25 4Z"
          fill="inherit"
        />
      </mask>
      <g mask="url(#mask0_840_71)">
        <path d="M1 18V24H25.5V4.5H21.5L17.5 6L16.5 8H14.5V9V11.5L10 13L8 18H1Z" fill="inherit" />
      </g>
      <path
        d="M13.9225 0.28244C14.0494 -0.0941465 14.5951 -0.0941467 14.722 0.28244L15.3493 2.14457C15.3911 2.26849 15.4906 2.36569 15.6176 2.40645L17.5251 3.01887C17.9108 3.14272 17.9108 3.67544 17.5251 3.79929L15.6176 4.41171C15.4906 4.45247 15.3911 4.54966 15.3493 4.67359L14.722 6.53572C14.5951 6.9123 14.0494 6.9123 13.9225 6.53572L13.2952 4.67359C13.2534 4.54966 13.1539 4.45247 13.0269 4.41171L11.1194 3.79929C10.7336 3.67544 10.7336 3.14272 11.1194 3.01887L13.0269 2.40645C13.1539 2.36569 13.2534 2.26849 13.2952 2.14457L13.9225 0.28244Z"
        fill="inherit"
      />
      <path
        d="M5.56639 3.44772C5.79476 2.76987 6.77702 2.76987 7.00539 3.44772L8.13461 6.79955C8.20976 7.02262 8.38897 7.19757 8.61747 7.27094L12.051 8.37329C12.7454 8.59622 12.7454 9.55512 12.051 9.77805L8.61748 10.8804C8.38898 10.9538 8.20976 11.1287 8.13461 11.3518L7.00539 14.7036C6.77702 15.3815 5.79476 15.3815 5.56639 14.7036L4.43717 11.3518C4.36202 11.1287 4.1828 10.9538 3.9543 10.8804L0.520782 9.77805C-0.173594 9.55512 -0.173594 8.59622 0.520782 8.37329L3.9543 7.27094C4.1828 7.19757 4.36202 7.02262 4.43717 6.79955L5.56639 3.44772Z"
        fill="inherit"
      />
      {/* <path
        d="M6.18561 17.875V23.5C6.18561 24.7426 7.18258 25.75 8.41241 25.75H21.7732C23.0031 25.75 24 24.7426 24 23.5V10C24 8.75736 23.0031 7.75 21.7732 7.75H16.2062V9.4375H21.7732C22.0807 9.4375 22.3299 9.68934 22.3299 10V23.5C22.3299 23.8107 22.0807 24.0625 21.7732 24.0625H8.41241C8.10495 24.0625 7.85571 23.8107 7.85571 23.5V17.875H6.18561Z"
        fill="inherit"
      />
      <path
        d="M13.9225 1.0704C14.0494 0.693817 14.5951 0.693817 14.722 1.0704L15.3493 2.93253C15.3911 3.05646 15.4906 3.15366 15.6176 3.19441L17.5251 3.80683C17.9108 3.93068 17.9108 4.4634 17.5251 4.58726L15.6176 5.19967C15.4906 5.24043 15.3911 5.33763 15.3493 5.46155L14.722 7.32368C14.5951 7.70027 14.0494 7.70027 13.9225 7.32368L13.2952 5.46155C13.2534 5.33763 13.1539 5.24043 13.0269 5.19967L11.1194 4.58726C10.7336 4.4634 10.7336 3.93068 11.1194 3.80683L13.0269 3.19441C13.1539 3.15366 13.2534 3.05646 13.2952 2.93253L13.9225 1.0704Z"
        fill="inherit"
      />
      <path
        d="M5.56639 4.23569C5.79476 3.55783 6.77702 3.55783 7.00539 4.23569L8.13461 7.58752C8.20976 7.81058 8.38898 7.98554 8.61748 8.0589L12.051 9.16126C12.7454 9.38419 12.7454 10.3431 12.051 10.566L8.61748 11.6684C8.38898 11.7417 8.20976 11.9167 8.13461 12.1398L7.00539 15.4916C6.77702 16.1694 5.79476 16.1694 5.56639 15.4916L4.43717 12.1398C4.36202 11.9167 4.1828 11.7417 3.9543 11.6684L0.520782 10.566C-0.173594 10.3431 -0.173594 9.38419 0.520782 9.16126L3.9543 8.0589C4.1828 7.98554 4.36202 7.81058 4.43717 7.58752L5.56639 4.23569Z"
        fill="inherit"
      />
      <g clip-path="url(#clip0_839_55)">
        <path
          d="M15.2309 14.6099V12.9933H14.769V14.6099H13.1525V15.0718H14.769V16.6884H15.2309V15.0718H16.8475V14.6099H15.2309Z"
          fill="inherit"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.1525 14.6099H14.769V12.9933H15.2309V14.6099H16.8475V15.0718H15.2309V16.6884H14.769V15.0718H13.1525V14.6099ZM14.7075 15.1334H13.0909V14.5483H14.7075V12.9318H15.2925V14.5483H16.9091V15.1334H15.2925V16.7499H14.7075V15.1334Z"
          fill="inherit"
        />
        <path
          d="M19.2955 19.6137H15.358V18.1818H17.5057C17.6955 18.1816 17.8774 18.1061 18.0116 17.9719C18.1458 17.8377 18.2214 17.6557 18.2216 17.4659V12.4546C18.2214 12.2648 18.1459 12.0828 18.0117 11.9486C17.8775 11.8144 17.6955 11.7389 17.5057 11.7386H12.4943C12.3045 11.7388 12.1225 11.8143 11.9883 11.9485C11.8541 12.0828 11.7786 12.2647 11.7784 12.4546V17.4659C11.7786 17.6557 11.8541 17.8377 11.9883 17.9719C12.1226 18.1061 12.3045 18.1816 12.4943 18.1818H14.6421V19.6137H10.7046C10.5147 19.6137 10.3326 19.6891 10.1983 19.8233C10.0641 19.9576 9.98865 20.1397 9.98865 20.3296V22.5114H10.7046V20.3296H14.6421V22.5114H15.358V20.3296H19.2955V22.5114H20.0114V20.3296C20.0114 20.1397 19.936 19.9576 19.8017 19.8233C19.6674 19.6891 19.4853 19.6137 19.2955 19.6137ZM12.4943 12.4546H17.5057L17.5064 17.4659H12.4943V12.4546Z"
          fill="inherit"
        />
      </g>
      <defs>
        <clipPath id="clip0_839_55">
          <rect width="10.5" height="10.5" fill="white" transform="translate(9.75 11.5)" />
        </clipPath>
      </defs> */}
    </svg>
  );
};

const CategorizeIcon = props => {
  return (
    <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.62775 1.31662C3.23589 1.66678 3.19099 1.95428 3.34533 3.12369C3.42366 3.71755 3.4145 3.75804 3.20191 3.75804C2.78578 3.75804 2.34681 4.07136 2.2185 4.46002C2.10068 4.81702 2.12837 5.19377 2.38377 6.70915C2.44124 7.05048 2.42756 7.06769 2.09715 7.06769C1.7161 7.06769 1 7.6727 1 7.99473C1 8.44539 2.89323 20.0039 3.00841 20.2568C3.28984 20.8743 3.04239 20.8579 12.0322 20.8579C21.0325 20.8579 20.7741 20.8753 21.0586 20.2509C21.255 19.8198 23.0768 8.20379 22.9975 7.88804C22.9071 7.52784 22.3434 7.06769 21.9925 7.06769C21.7155 7.06769 21.7112 7.05578 21.7837 6.4885C21.8243 6.1699 21.8879 5.57736 21.9248 5.17159C21.9899 4.45649 21.9815 4.42339 21.6541 4.09596C21.445 3.8869 21.2033 3.75804 21.0202 3.75804H20.7242L20.8018 2.77761C20.8877 1.6915 20.7795 1.37112 20.2444 1.12731C20.0414 1.03486 17.7776 1 11.9736 1H3.98222L3.62775 1.31662ZM19.126 2.95821C19.1004 3.12513 19.0566 3.37335 19.0288 3.50982L18.9782 3.75804H12.0445H5.11081L5.04241 3.34434C5.00479 3.11685 4.97346 2.86863 4.97279 2.79273C4.9718 2.68384 6.46456 2.65483 12.072 2.65483H19.1725L19.126 2.95821ZM20.1488 6.0823C20.1071 6.45044 20.0457 6.82278 20.0124 6.90971C19.9605 7.04475 18.8042 7.06769 12.0326 7.06769H4.11361L4.05337 6.70915C4.02028 6.512 3.96081 6.13967 3.9211 5.88173L3.84895 5.41287H12.0367H20.2244L20.1488 6.0823ZM21.2992 8.7704C21.2992 8.79665 20.9269 11.1382 20.4718 13.9738C20.0167 16.8093 19.6444 19.1459 19.6444 19.1662C19.6444 19.1865 16.2189 19.2031 12.0322 19.2031C7.84546 19.2031 4.41997 19.1865 4.41997 19.1662C4.41997 19.1459 4.04764 16.8093 3.59256 13.9738C3.13748 11.1382 2.76515 8.79665 2.76515 8.7704C2.76515 8.74403 6.93531 8.72252 12.0322 8.72252C17.129 8.72252 21.2992 8.74403 21.2992 8.7704Z"
        fill="currentColor"
      />
    </svg>
  );
};

const NodePopoverButton = ({
  icon,
  onClick,
  text,
  disabled,
  hidden,
  ...props
}: {
  icon: ReactNode;
  className?: string;
  onClick?: (e: any) => void;
  text: string;
  disabled?: boolean;
  hidden?: boolean;
}) => {
  return (
    <>
      <button
        data-tip={text}
        data-place={'bottom'}
        data-delay-show={250}
        className={classNames(
          'bg-transparent pa2 justify-center items-center',
          {
            dn: hidden,
            flex: !hidden,
            'white pointer dim': !disabled,
            'gray not-allowed': disabled,
          },
          props.className,
        )}
        onClick={e => {
          if (!disabled) {
            onClick(e);
          }
        }}
      >
        {icon}
      </button>
      <ReactTooltip />
    </>
  );
};

const popoverStyle = state => {
  const coords = state.getIn(['tree', 'view', 'selected', 'coordinates']);
  const isSelect = state.getIn(['tree', 'view', 'selected', 'type']) === 'SELECT';
  const yGapPx = 80;
  const treeUUID = state.getIn(['tree', 'uuid']);
  const halfBarLengthPx = state.getIn(['trees', treeUUID, 'methodology']) === 'PROACT' ? 13 * 18 : 138;

  if (coords && isSelect) {
    return {
      top: coords.get('y1') - yGapPx,
      left: (coords.get('x1') + coords.get('x2')) / 2 - halfBarLengthPx,
    };
  } else {
    return { display: 'none' };
  }
};

const NodePopoverAIButton = ({
  disabled,
  state,
  dispatch,
  hidden,
  getTurboSuggestions,
  getSuggestions,
}: {
  hidden?: boolean;
  state: any;
  dispatch: any;
  disabled?: boolean;
  getTurboSuggestions: () => void;
  getSuggestions: () => void;
}) => {
  const [dropdown, setDropdown] = useState(false);
  // const path = state.getIn(['tree', 'view', 'selected', 'path']);

  // const onClickHandler = nodeType => () => {
  //   dispatch(Map({ type: 'ASSIGN_TYPE', nodeType, path }));
  //   dispatch(Map({ type: 'MARK_POSSIBLE', path }));
  // };

  return (
    <div
      className={classNames('pa2 pointer justify-center items-center relative', {
        dn: hidden,
        'flex white': !hidden,
      })}
      onMouseEnter={() => {
        setDropdown(!disabled);
      }}
      onMouseLeave={() => {
        setDropdown(false);
      }}
    >
      <div
        className={classNames('flex justify-center items-center relative', {
          'gray not-allowed': disabled,
        })}
      >
        {/* <img className={classNames({ 'fill-orange': true })} src={aiSrc} /> */}
        <AiRootIcon
          // style={{ top: -2, left: -2 }}
          className={classNames('relative', {
            // orange: dropdown !== false,
          })}
        />
        <CaretDown16 className={'absolute top-0'} style={{ right: '-14px' }} />
      </div>

      <div
        className={classNames('bg-dark-gray justify-center items-center absolute', {
          dn: !dropdown,
          'flex bt b--gray': dropdown,
        })}
        style={{ top: '2.2rem' }}
      >
        <NodePopoverButton icon={<AiIcon />} onClick={getSuggestions} text="AI Suggest" />
        <NodePopoverButton
          icon={<AiTurboIcon className="red" />}
          onClick={getTurboSuggestions}
          text="AI Suggest Turbo"
        />
      </div>
    </div>
  );
};

const NodePopoverButtonWithDropdown = ({
  hidden,
  state,
  dispatch,
  disabled,
  methodology,
  text,
  nodeType,
}: {
  hidden?: boolean;
  state: any;
  dispatch: any;
  disabled?: boolean;
  methodology: string;
  text: string;
  nodeType: string;
}) => {
  const [dropdown, setDropdown] = useState(false);
  const path = state.getIn(['tree', 'view', 'selected', 'path']);

  const onClickHandler = nodeType => () => {
    dispatch(Map({ type: 'ASSIGN_TYPE', nodeType, path }));
    dispatch(Map({ type: 'MARK_POSSIBLE', path }));
  };

  return (
    <div
      className={classNames('pa2 pointer justify-center items-center relative', {
        dn: hidden,
        'flex white': !hidden,
      })}
      onMouseEnter={() => {
        setDropdown(!disabled);
      }}
      onMouseLeave={() => {
        setDropdown(false);
      }}
    >
      <div
        className={classNames('flex justify-center items-center relative', {
          'gray not-allowed': disabled,
        })}
      >
        <TagGroup24 />
        <CaretDown16 className={'absolute top-0'} style={{ right: '-10px' }} />
      </div>

      <div
        className={classNames('bg-dark-gray justify-center items-center absolute', {
          dn: !dropdown,
          'flex bt b--gray': dropdown,
        })}
        style={{ top: '2.5rem' }}
      >
        <NodePopoverButton
          icon={<Help24 />}
          text={methodology === 'PROACT' ? 'Hypothesis' : text}
          onClick={onClickHandler(methodology === 'PROACT' ? 'hypothesis' : nodeType)}
        />
        <NodePopoverButton
          icon={<Debug24 />}
          text={'Contributing Factor'}
          hidden={methodology !== 'PROACT'}
          onClick={onClickHandler('contributingFactor')}
        />
        <NodePopoverButton
          icon={<Chemistry24 />}
          text={'Physical Root'}
          hidden={methodology !== 'PROACT'}
          onClick={onClickHandler('physical')}
        />
        <NodePopoverButton
          icon={<Group24 />}
          text={'Human Root'}
          hidden={methodology !== 'PROACT'}
          onClick={onClickHandler('human')}
        />
        <NodePopoverButton
          icon={<Tree24 />}
          hidden={methodology !== 'PROACT'}
          onClick={onClickHandler('systemic')}
          text={'Systemic Root'}
        />
        <NodePopoverButton
          icon={<ListChecked24 />}
          onClick={onClickHandler('correctiveAction')}
          text={'Corrective Action'}
        />
      </div>
    </div>
  );
};

const CategorizeNodePopoverButtonWithDropdown = ({
  onSelectOption,
  onDeselectOption,
  nodeOid,
  categories,
  nodeType,
  treeCategories,
  // selectedCategories,
}) => {
  const [dropdown, setDropdown] = useState(false);
  const selectedCategories = treeCategories.toJS
    ? treeCategories.toJS().filter(c => c.nodeUuid === nodeOid)
    : treeCategories.filter(c => c.nodeUuid === nodeOid);
  const selectedCategoryIds = selectedCategories?.map(c => c.tagUuid);

  const availableOptions = useMemo(() => {
    const tags = categories[nodeType];
    return tags ? tags : [];
  }, [categories, nodeType]);

  const options = availableOptions
    .filter(cat => !selectedCategoryIds.includes(cat.tagUuid))
    .map(category => ({ value: category.tagUuid, label: category.tagName }));

  const selectedOptions = selectedCategories.toJS
    ? selectedCategories.toJS().map(category => ({ label: category.tagName, value: category.tagUuid }))
    : selectedCategories.map(category => ({ label: category.tagName, value: category.tagUuid }));

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const isDebugging = params.get('debug') === 'true';

  return (
    <div
      className={classNames('pa2 pointer justify-center items-center relative flex')}
      onMouseEnter={() => {
        setDropdown(true);
      }}
      onMouseLeave={() => {
        setDropdown(false);
      }}
      onMouseOver={() => {
        setDropdown(true);
      }}
    >
      <div className={classNames('flex justify-center items-center relative')}>
        <CategorizeIcon className="white" />
        <CaretDown16 className="white absolute" style={{ right: -15, top: '0px' }} />
      </div>

      <div
        className={classNames('pa3 w6 bg-dark-gray justify-center items-center absolute', {
          dn: !dropdown,
          'flex bt b--gray column flex-column': dropdown,
        })}
        style={{ top: '2.5rem' }}
      >
        {isDebugging ? (
          <div className="f7 debug-pane">
            <strong className="pb1 white">Info</strong>
            <div className="flex white">
              <span className="pr1">node:</span>
              <span>{nodeOid}</span>
            </div>

            <div className="flex white">
              <span className="pr1">type:</span>
              <span>{nodeType}</span>
            </div>

            <div className="pt2 white">
              <strong>Selected Categories</strong>
              {selectedCategories.map(cat => (
                <p>{cat.tagName}</p>
              ))}
            </div>
          </div>
        ) : null}
        {/* @ts-ignore */}
        <Select<BaseOption, true, GroupBase<any>>
          styles={{
            multiValue: provided => ({
              ...provided,
              transition: 'all 125ms ease-in-out',
              cursor: 'pointer',
            }),
            multiValueLabel: provided => ({
              ...provided,
              fontSize: '0.88rem',
              color: 'white',
              backgroundColor: '#0a0a0a',
              letterSpacing: '0.1px',
              borderRight: 'solid rgba(255, 255, 255, 0.1) 1px',
              paddingRight: 5,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }),
            multiValueRemove: provided => ({
              ...provided,
              color: 'white',
              backgroundColor: '#0a0a0a',
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              padding: '0 3px',
              '&:hover': {
                backgroundColor: 'black',
              },
              '&:hover svg': {
                color: 'red',
              },
            }),
          }}
          placeholder="Select Category"
          className="w-100 w100"
          isDark
          isOptionSelected={option => selectedCategoryIds.includes(option.value)}
          hideSelectedOptions={false}
          noOptionsMessage={() => 'No categories'}
          isMulti
          // @ts-ignore
          defaultValue={selectedOptions}
          value={selectedOptions}
          selected={selectedCategoryIds}
          closeMenuOnSelect={false}
          menuShouldScrollIntoView
          blurInputOnSelect={false}
          isClearable
          onChange={(_, meta) => {
            switch (meta.action) {
              case 'deselect-option':
              case 'pop-value':
              case 'clear':
              case 'remove-value': {
                if (meta.removedValues?.length) {
                  meta.removedValues.forEach(opt => onDeselectOption({ node: nodeOid, tag: opt.value }));
                  break;
                }

                onDeselectOption({ node: nodeOid, tag: meta.removedValue.value });
                break;
              }
              case 'select-option': {
                onSelectOption({ node: nodeOid, tag: meta.option.value });
                break;
              }
              default:
                break;
            }
          }}
          options={options}
          controlShouldRenderValue
          isSearchable={options.length > 5}
        />
      </div>
    </div>
  );
};

const ShowHideNodePopoverButtonWithDropdown = ({ hidden, state, dispatch }) => {
  const [dropdown, setDropdown] = useState(false);
  const path = state.getIn(['tree', 'view', 'selected', 'path']);

  return (
    <div
      className={classNames('pa2 pointer justify-center items-center relative flex white')}
      onMouseEnter={() => {
        setDropdown(true);
      }}
      onMouseLeave={() => {
        setDropdown(false);
      }}
      onMouseOver={() => {
        setDropdown(true);
      }}
    >
      <div className={classNames('flex justify-center items-center relative')}>
        <DataView24 />
        <CaretDown16 className={'absolute top-0'} style={{ right: '-10px' }} />
      </div>

      <div
        className={classNames('bg-dark-gray justify-center items-center absolute', {
          dn: !dropdown,
          'flex bt b--gray': dropdown,
        })}
        style={{ top: '2.5rem' }}
      >
        <NodePopoverButton
          text={'Show'}
          icon={<View24 />}
          onClick={async () => {
            await dispatch(
              Map({
                type: 'SET_HIDDEN',
                path,
                hide: !hidden,
                toggleAll: false,
              }),
            );
            await dispatch(Map({ type: 'FIX_LAYOUT' }));
          }}
        />
        <NodePopoverButton
          text={'Show All'}
          icon={<Category24 />}
          onClick={async () => {
            await dispatch(
              Map({
                type: 'SET_HIDDEN',
                path,
                hide: !hidden,
                toggleAll: true,
              }),
            );
            await dispatch(Map({ type: 'FIX_LAYOUT' }));
          }}
        />
      </div>
    </div>
  );
};

const NodePopoverDivider = props => {
  return (
    <div
      className={classNames('bg-white-30 h2', props.className, {
        mh2: !props.className?.includes('mh') && !props.className?.includes('ml') && !props.className?.includes('mr'),
      })}
      style={{ width: '1px' }}
    />
  );
};

export const NodePopover = ({
  state,
  dispatch,
  isViewOnly,
  tags,
}: {
  state: any;
  dispatch: any;
  isViewOnly: boolean;
  tags: Record<string, any>[];
}) => {
  const token = state.get('token');
  const username = state.get('username');
  const user = state.getIn(['users', username]);
  const aiFeatureEnabled = user.getIn(['orgFeatures', 'aiFeatures'], false);
  const style = popoverStyle(state);
  const treeUUID = state.getIn(['tree', 'uuid']);
  const methodology = state.getIn(['trees', treeUUID, 'methodology']);
  const path = state.getIn(['tree', 'view', 'selected', 'path'], List());

  const node = state.getIn(['tree', 'elements'], Map()).getIn(path, Map());
  const categories = useMemo(() => {
    return {
      human: tags.filter(t => t.tagType.includes('Human')),
      systemic: tags.filter(t => t.tagType.includes('Systemic')),
      physical: tags.filter(t => t.tagType.includes('Physical')),
      failureEvent: tags.filter(t => t.tagType.includes('FailureEvent')),
      correctiveAction: tags.filter(t => t.tagType.includes('CorrectiveAction')),
      failureMode: tags.filter(t => t.tagType.includes('FailureMode')),
    };
  }, [tags]);
  const nodeType = node.get('type');
  const shouldRenderCategorySelect = useMemo(() => {
    // return true;
    // if (nodeType === 'failureEvent') {
    //   console.warn('node', node);
    //   // debugger;
    // }
    if (nodeType !== 'hypothesis') {
      return true;
    }
    // if (nodeType === 'physical' || nodeType === 'human' || nodeType === 'systemic') {
    //   return true;
    // }

    return false;
  }, [node, nodeType]);
  const treeCategories = state.getIn(['tree', 'categories'], List());
  // const selectedCategories = useMemo(() => {
  //   console.warn('re-running selected categories', {

  //     totalCategories: treeCategories.length,
  //     totalNodeCategories: treeCategories.filter(c => c.nodeUuid === node.get('oid')).length,
  //     categories: treeCategories,
  //     nodeCategories: treeCategories.filter(c => c.nodeUuid === node.get('oid'))
  //   })
  //   return treeCategories.filter(c => c.nodeUuid === node.get('oid'))
  // }, [treeCategories])

  const children = node.get('children');
  const nodeIsHidden = node.get('hidden');
  const childIsHidden =
    children && !children.isEmpty() ? children.filter(ch => ch.get('hidden')).size > 0 : node.get('hidden');
  const isHidden = node.get('hidden');
  const isPossible = node.get('possible');
  const isVerified = node.get('verified');
  const isFailureEvent = path.size < 2;
  const isTopBox = path.size < 4;
  const showCorrectiveActions = path.size < 2;

  async function removeCauseCategory({ node, tag }: { node: string; tag: string }) {
    // const categories = state.getIn(['tree', 'categories'], [])

    try {
      const results = await removeTag({ nodeUuid: node, tagUuid: tag, treeUuid: treeUUID, token });

      if (!results || !results.ok) {
        throw new Error('Failed to remove category');
      }

      // const updatedCategories = categories.filter(c => {
      //   return (c.tagUuid !== tag && c.nodeUuid !== node)
      // })
      dispatch({
        type: 'REMOVE_NODE_CATEGORY',
        payload: {
          node,
          category: tag,
          tree: treeUUID,
        },
      });

      // dispatch({
      //   type: 'UPDATE_TREE_CATEGORIES',
      //   meta: {
      //     operation: 'remove'
      //   },
      //   payload: {
      //     categories: updatedCategories,
      //     node
      //   }
      // })
    } catch (error) {
      console.error(error);
    }
  }

  async function addCauseCategory({ node, tag }: { node: string; tag: string }) {
    // const categories = state.getIn(['tree', 'categories'], [])

    try {
      const results = await assignTag({ nodeUuid: node, tagUuid: tag, treeUuid: treeUUID, token });

      if (!results) {
        throw new Error('Failed to assign tag');
      }

      // const updatedCategories = uniqBy([...categories, results], ['tagUuid', 'nodeUuid'])

      dispatch({
        type: 'ADD_NODE_CATEGORY',
        payload: {
          node,
          category: results,
        },
      });
    } catch (error) {
      console.error(error);
    }
  }

  async function getAISuggestions() {
    const treeUuid = treeUUID;
    const nodeUuid = node.get('oid');

    dispatch(Map({ type: 'SHOW_TOAST', message: 'Generating suggestions...' }));

    const results = await fetch(`/api/v0/suggestNextNode/${treeUuid}/${nodeUuid}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!results) {
      dispatch(Map({ type: 'SHOW_TOAST', message: 'Error getting suggestions.', style: 'ERROR' }));
      return;
    }

    const json = await results.json();

    if (!json.ok) {
      console.error(`[getAISuggestions, ${json.status}]: ${json.error}`);
      dispatch(
        Map({
          type: 'SHOW_TOAST',
          message: json.error,
          style: 'ERROR',
        }),
      );
      return;
    }

    dispatch(Map({ type: 'HIDE_TOAST' }));

    const data = json.data;
    dispatch(Map({ type: 'PROCESS_SUGGESTIONS', path, data }));
  }

  async function getAISuggestionsTurbo() {
    const treeUuid = treeUUID;
    const nodeUuid = node.get('oid');

    dispatch(Map({ type: 'SHOW_TOAST', message: 'Generating tree...' }));

    const results = await fetch(`/api/v0/suggestNextNodeTurbo/${treeUuid}/${nodeUuid}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!results) {
      dispatch(Map({ type: 'SHOW_TOAST', message: 'Error getting suggestions.', style: 'ERROR' }));
      return;
    }

    const json = await results.json();

    if (!json.ok) {
      console.error(`[getAISuggestionsTurbo, ${json.status}]: ${json.error}`);
      dispatch(
        Map({
          type: 'SHOW_TOAST',
          message: json.error,
          style: 'ERROR',
        }),
      );
      return;
    }

    dispatch(Map({ type: 'HIDE_TOAST' }));

    const data = json.data;
    dispatch(Map({ type: 'OPEN_TREE', treeUuid }));
  }

  const hasClipboard = state.get('clipboard', Map()).size === 0;
  const i18n = state.get('i18n');

  return (
    <div style={style} className="pa1 absolute flex justify-center items-center z-1 bg-dark-gray">
      <NodePopoverButton
        text={_(i18n, 'Add Child')}
        icon={<CategoryNew24 />}
        disabled={isHidden || isViewOnly}
        onClick={() => {
          dispatch(Map({ type: 'CREATE_CHILD', path }));
        }}
      />
      {aiFeatureEnabled && (methodology === 'PROACT' || methodology === '5WHYS') ? (
        <NodePopoverAIButton
          dispatch={dispatch}
          state={state}
          getTurboSuggestions={getAISuggestionsTurbo}
          getSuggestions={getAISuggestions}
          disabled={isHidden || isViewOnly}
        />
      ) : null}

      <NodePopoverButton
        text={_(i18n, 'Delete')}
        icon={<TrashCan24 />}
        disabled={isFailureEvent || isViewOnly}
        onClick={() => dispatch(Map({ type: 'DELETE_SUBTREE', path }))}
      />
      <NodePopoverDivider />
      <NodePopoverButton
        text={_(i18n, 'Cut')}
        icon={<Cut24 />}
        disabled={isFailureEvent || isHidden || isViewOnly}
        onClick={() => {
          dispatch(Map({ type: 'CUT_SUBTREE', path }));
          dispatch(
            Map({
              type: 'SHOW_TOAST',
              message: 'Your logic has been cut.',
            }),
          );
        }}
      />
      <NodePopoverButton
        text={_(i18n, 'Copy')}
        icon={<Copy24 />}
        disabled={isFailureEvent || isHidden || isViewOnly}
        onClick={() => {
          dispatch(Map({ type: 'COPY_SUBTREE', path }));
          dispatch(
            Map({
              type: 'SHOW_TOAST',
              toast: Map({ message: 'Your logic has been copied.' }),
            }),
          );
        }}
      />
      <NodePopoverButton
        text={_(i18n, 'Paste')}
        disabled={hasClipboard || isHidden || isViewOnly}
        icon={<Paste24 />}
        onClick={() => dispatch(Map({ type: 'PASTE_SUBTREE', path }))}
      />
      <div className={classNames({ dn: methodology !== 'PROACT' })}>
        <NodePopoverDivider />
      </div>

      <NodePopoverButtonWithDropdown
        dispatch={dispatch}
        state={state}
        methodology={methodology}
        text={node.get('id').slice(0, 1).toUpperCase() + node.get('id').slice(1, node.get('id').length)}
        nodeType={node.get('id')}
        disabled={
          (methodology !== 'PROACT' && showCorrectiveActions) ||
          (methodology === 'PROACT' && (isTopBox || isHidden)) ||
          isViewOnly
        }
      />

      {shouldRenderCategorySelect ? (
        <>
          {/* @ts-ignore */}
          <CategorizeNodePopoverButtonWithDropdown
            // onChange={(data, meta) => {
            //   if (!data && meta.action === 'clear') {
            //     removeCauseCategory({ node: node.get('oid'), tag: meta.removedValues[0].value });
            //   } else if (meta.action === 'select-option') {
            //     addCauseCategory({ node: node.get('oid'), tag: data.value });
            //   }
            // }}
            // if (!data && meta.action === 'clear') {
            //     removeCauseCategory({ node: node.get('oid'), tag: meta.removedValues[0].value });
            //   } else if (meta.action === 'select-option') {
            //     addCauseCategory({ node: node.get('oid'), tag: data.value });
            //   }
            treeCategories={treeCategories}
            onSelectOption={addCauseCategory}
            onDeselectOption={removeCauseCategory}
            nodeOid={node.get('oid')}
            // selectedCategories={selectedCategories}
            categories={categories}
            nodeType={nodeType}
          />
          <NodePopoverDivider className="ml3" />
        </>
      ) : null}

      {isVerified ? (
        <NodePopoverButton
          text={_(i18n, 'Mark Unverified')}
          icon={<MisuseOutline24 />}
          hidden={methodology !== 'PROACT'}
          disabled={isTopBox || isHidden || isViewOnly}
          onClick={() => dispatch(Map({ type: 'MARK_HYPOTHETICAL', path }))}
        />
      ) : (
        <NodePopoverButton
          text={_(i18n, 'Mark Verified')}
          icon={<Checkmark24 />}
          hidden={methodology !== 'PROACT'}
          disabled={isTopBox || isHidden || isViewOnly}
          onClick={() => dispatch(Map({ type: 'MARK_VERIFIED', path }))}
        />
      )}
      {isPossible ? (
        <NodePopoverButton
          text={_(i18n, 'Mark Not True')}
          icon={<ThumbsDown24 />}
          hidden={methodology !== 'PROACT'}
          disabled={isTopBox || isHidden || isViewOnly}
          onClick={() => dispatch(Map({ type: 'MARK_NOT_TRUE', path }))}
        />
      ) : (
        <NodePopoverButton
          text={_(i18n, 'Mark True')}
          icon={<ThumbsUp24 />}
          hidden={methodology !== 'PROACT'}
          disabled={isTopBox || isHidden || isViewOnly}
          onClick={() => dispatch(Map({ type: 'MARK_POSSIBLE', path }))}
        />
      )}
      {nodeIsHidden || childIsHidden ? (
        <ShowHideNodePopoverButtonWithDropdown
          dispatch={dispatch}
          hidden={nodeIsHidden ? nodeIsHidden : childIsHidden}
          state={state}
        />
      ) : (
        <NodePopoverButton
          text={_(i18n, 'Hide')}
          icon={<ViewOff24 />}
          disabled={isViewOnly}
          onClick={() =>
            dispatch(
              Map({
                type: 'SET_HIDDEN',
                path,
                hide: !nodeIsHidden,
                toggleAll: true,
              }),
            )
          }
        />
      )}
      <NodePopoverButton
        text={_(i18n, 'Connect')}
        icon={<TreeView24 className={'rotate-270'} />}
        disabled={isHidden || isViewOnly}
        onClick={() => {
          dispatch(
            Map({
              type: 'SHOW_TOAST',
              message: "Please click on the node you'd like to connect, or on the canvas to cancel",
            }),
          );
          dispatch(Map({ type: 'CONNECT', node }));
        }}
      />
      <NodePopoverDivider />
      <NodePopoverButton
        text={_(i18n, 'Insert Template')}
        icon={<CategoryAdd24 />}
        disabled={isHidden || isViewOnly}
        onClick={() => {
          dispatch(
            Map({
              type: 'COPY_TEMPLATE_SUBTREE',
              treeUuid: treeUUID,
              path,
            }),
          );
          dispatch(Map({ type: 'SET_URL', url: '/templates' }));
        }}
      />
      <NodePopoverButton
        text={_(i18n, 'Attachments')}
        icon={<Attachment24 />}
        disabled={isHidden}
        onClick={() => dispatch(Map({ type: 'SET_CONTEXTUAL_DRAWER', drawer: 'INFORMATION' }))}
      />
    </div>
  );
};
